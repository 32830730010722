<script setup>
import { computed, markRaw, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useAppStore } from "@/stores/AppStore";
import SideNav from "./SideNav.vue";
import MobileNavBar from "./MobileNavBar.vue";
import { sumBy } from "lodash";

// ICONS
import { BeakerIcon, ChartBarIcon } from "@heroicons/vue/20/solid";
import ManpowerIcon from "@/Components/Icons/ManpowerIcon.vue";
import ProjectsIcon from "@/Components/Icons/ProjectsIcon.vue";
import CalenderIcon from "@/Components/Icons/CalenderIcon.vue";
import SettingsIcon from "@/Components/Icons/SettingsIcon.vue";
import WorkOrderIcon from "@/Components/Icons/WorkOrderIcon.vue";
import ChartLine from "@/Components/Icons/ChartLine.vue";
import ContactsIcon from "@/Components/Icons/ContactsIcon.vue";
import CustomerIcon from "@/Components/Icons/CustomerIcon.vue";
import FileContract from "@/Components/Icons/FileContract.vue";
import InventoryIcon from "@/Components/Icons/InventoryIcon.vue";
import SubcontractorIcon from "@/Components/Icons/SubcontractorIcon.vue";
import AnnouncementIcon from "@/Components/Icons/AnnouncementIcon.vue";
import TravelIcon from "@/Components/Icons/TravelIcon.vue";
import FleetIcon from "@/Components/Icons/FleetIcon.vue";
import PmCheckoutIcon from "@/Components/Icons/PmCheckoutIcon.vue";
import TimeIcon from "@/Components/Icons/TimeIcon.vue";
import EmployeeIcon from "@/Components/Icons/EmployeeIcon.vue";
import TCAIcon from "@/Components/Icons/TCAIcon.vue";
import ConesIcon from "@/Components/Icons/ConesIcon.vue";

const app = useAppStore();
const page = usePage();

const canUserAccess = ref(page.props.can);
const canViewSettings = computed(() =>
    sumBy(canUserAccess.value, (val) => val.startsWith("nav_settings")),
);

const navItems = markRaw([
    {
        icon: WorkOrderIcon,
        title: "Schedule",
        link: "/schedule",
        children: [],
        display: canUserAccess.value.nav_schedule,
    },
    {
        icon: CalenderIcon,
        title: "Calendar",
        urlSlug: "calendar",
        link: "/calendar",
        children: [],
        display: canUserAccess.value.nav_calendar,
    },
    {
        icon: ProjectsIcon,
        title: "Projects",
        urlSlug: "projects",
        link: "undefined",
        display:
            canUserAccess.value.nav_projects || canUserAccess.value.nav_adp,
        children: [
            {
                label: "Projects",
                link: "/projects",
                display: canUserAccess.value.nav_projects,
            },
            {
                label: "ADP",
                link: "/adp",
                display: canUserAccess.value.nav_adp,
            },
        ],
    },
    {
        icon: ManpowerIcon,
        title: "Manpower",
        urlSlug: "manpower",
        link: undefined,
        display:
            canUserAccess.value.nav_manpower_schedule ||
            canUserAccess.value.nav_manpower_crew ||
            canUserAccess.value.nav_manpower_assign,
        children: [
            {
                label: "Schedule",
                link: "/manpower/schedule",
                display: canUserAccess.value.nav_manpower_schedule,
            },
            {
                label: "Crew",
                link: "/manpower/crew",
                display: canUserAccess.value.nav_manpower_crew,
            },
            {
                label: "Assign",
                link: "/manpower/assign",
                display: canUserAccess.value.nav_manpower_assign,
            },
            {
                title: "Transfer",
                urlSlug: "manpower/transfer",
                link: undefined,
                display: canUserAccess.value.nav_manpower_fleets,
                children: [
                    {
                        label: "Crew",
                        link: "/manpower/transfer/crew",
                        display: canUserAccess.value.nav_manpower_transfer_crew,
                        isGrandChild: true,
                    },
                    {
                        label: "Fleets",
                        link: "/manpower/transfer/fleets",
                        display:
                            canUserAccess.value.nav_manpower_transfer_fleets,
                        isGrandChild: true,
                    },
                ],
            },
        ],
    },
    {
        icon: PmCheckoutIcon,
        title: "PM Check Out",
        link: "/checkout",
        urlSlug: "checkout",
        display: canUserAccess.value.nav_pm_checkout,
        children: [],
    },
    {
        icon: CustomerIcon,
        title: "Customers",
        urlSlug: "customers",
        link: "/customers",
        children: [],
        display: canUserAccess.value.nav_customers,
    },
    {
        icon: ContactsIcon,
        title: "Contacts",
        urlSlug: "contacts",
        link: "/contacts",
        children: [],
        display: canUserAccess.value.nav_contacts,
    },
    {
        icon: SubcontractorIcon,
        title: "SubContractors",
        urlSlug: "subcontractors",
        link: "/subcontractors",
        children: [],
        display: canUserAccess.value.nav_sub_contractors,
    },
    {
        icon: FleetIcon,
        title: "Fleet/Equipment",
        urlSlug: "equipment",
        link: "/equipment",
        children: [],
        display: canUserAccess.value.nav_equipment,
    },
    {
        icon: ConesIcon,
        title: "HR Links",
        urlSlug: "hr",
        link: "/hr-self-service",
        display: canUserAccess.value.nav_hr_links,
        children: [
            {
                label: "Employee Handbook",
                link: "https://s3-us-west-1.amazonaws.com/holbrook-asphalt/HR/200921_EmployeePolicyManualV13.pdf",
                external: true,
            },
            {
                label: "Safety Manual",
                link: "https://s3-us-west-1.amazonaws.com/holbrook-asphalt/HR/Holbrook_Safety_Manual.pdf",
                external: true,
            },
            {
                label: "Employment Opportunities",
                link: "https://recruiting.paylocity.com/Recruiting/Jobs/All/68bf8f66-c07a-4b0e-947b-ee3d72e0ab05/Holbrook-Asphalt-LLC",
                external: true,
            },
            {
                label: "Refer an Employee",
                link: "https://holbrookasphalt.com/employee-referral-form/",
                external: true,
            },
            {
                label: "Paylocity",
                link: "https://access.paylocity.com/",
                external: true,
            },
            {
                label: "Training Videos",
                link: "/training/videos",
                external: true,
            },
        ],
    },
    {
        icon: TCAIcon,
        title: "TCA",
        urlSlug: "time-card-approval",
        link: "",
        children: [
            {
                label: "TCA",
                link: "/time-card-approval",
                exact: true,
                display: canUserAccess.value.nav_tca_full,
            },
            {
                label: "Supervisor",
                link: "/time-card-approval/supervisor",
                exact: false,
                display: canUserAccess.value.nav_tca_supervisor,
            },
        ],
        display:
            canUserAccess.value.nav_tca_full ||
            canUserAccess.value.nav_tca_supervisor,
    },
    {
        icon: EmployeeIcon,
        title: "HR",
        urlSlug: "hr",
        link: "/hr",
        children: [
            {
                label: "Employees",
                link: "/employees",
                display: canUserAccess.value.nav_hr_employees,
            },
            {
                label: "Paylocity Report",
                link: "/reports/paylocity",
                display: canUserAccess.value.nav_hr_paylocity_report,
            },
        ],
        display:
            canUserAccess.value.nav_hr_employees ||
            canUserAccess.value.nav_hr_paylocity_report,
    },
    {
        icon: TimeIcon,
        title: "TimeCard",
        urlSlug: "time",
        link: "/time/card",
        children: [],
        display: canUserAccess.value.nav_timecard,
    },
    {
        icon: AnnouncementIcon,
        title: "Announcement",
        urlSlug: "announcements",
        link: "/announcements",
        children: [],
        display: canUserAccess.value.nav_announcement,
    },
    {
        icon: FileContract,
        title: "Finance",
        urlSlug: "billing",
        link: "/billing",
        children: [
            { label: "Billing", link: "/billing" },
            { label: "By Project", link: "/billing/by-project" },
        ],
        display:
            canUserAccess.value.nav_finance_billing ||
            canUserAccess.value.nav_finance_by_project,
    },
    {
        icon: ChartBarIcon,
        title: "Reports",
        urlSlug: "reports",
        link: "#",
        children: [
            {
                label: "Payroll Report",
                link: "/reports/payroll",
            },
            {
                label: "Project Report",
                link: "/reports/project",
            },
        ],
        display: canUserAccess.value.nav_reports_billing,
    },
    {
        icon: BeakerIcon,
        title: "Materials",
        urlSlug: "materials",
        link: "/materials",
        display: canUserAccess.value.nav_materials,
        children: [],
    },
    {
        icon: InventoryIcon,
        title: "Inventory",
        urlSlug: "inventory",
        link: "/inventory",
        display:
            canUserAccess.value.nav_inventory ||
            canUserAccess.value.nav_inventory_mrp,
        children: [
            {
                label: "Inventory",
                link: "/inventory",
                exact: true,
                display: canUserAccess.value.nav_inventory,
            },
            {
                label: "MRP",
                link: "/inventory/mrp",
                display: canUserAccess.value.nav_inventory_mrp,
            },
        ],
    },
    {
        icon: SettingsIcon,
        title: "Settings",
        urlSlug: false,
        display: canViewSettings,
        link: "",
        children: [
            {
                label: "Material Categories",
                link: "/material-categories",
                display: canUserAccess.value.nav_settings_material_categories,
            },
            {
                label: "Units of Measurement",
                link: "/measure-units",
                display: canUserAccess.value.nav_settings_measure_units,
            },
            {
                label: "Inventory Types",
                link: "/inventory-types",
                display: canUserAccess.value.nav_settings_inventory_types,
            },
            {
                label: "Load Sizes",
                link: "/load-sizes",
                display: canUserAccess.value.nav_settings_load_sizes,
            },
            {
                label: "Services",
                link: "/services",
                display: canUserAccess.value.nav_settings_services,
            },
            {
                label: "Locations",
                link: "/locations",
                display: canUserAccess.value.nav_settings_locations,
            },
            {
                label: "Groups",
                link: "/groups",
                display: canUserAccess.value.nav_settings_groups,
            },
            {
                label: "Regions",
                link: "/regions",
                display: canUserAccess.value.nav_settings_regions,
            },
            {
                label: `Project Status's`,
                link: "/management/project-statuses",
                display: canUserAccess.value.nav_settings_project_statuses,
            },
            {
                label: "Roles/Permission",
                link: "/guard",
                display: canUserAccess.value.nav_settings_roles,
            },
            {
                label: "Project Categories",
                link: "/project-categories",
                display: canUserAccess.value.nav_settings_project_categories,
            },
            {
                label: "TimeCard Types ",
                link: "/timecard-types",
                display: canUserAccess.value.nav_settings_time_card_types,
            },
            {
                label: "Location Types ",
                link: "/location-types",
                display: canUserAccess.value.nav_settings_location_types,
            },
            {
                label: "Companies",
                link: "/companies",
                display: canUserAccess.value.nav_settings_companies,
            },
        ],
    },
    {
        icon: TravelIcon,
        title: "Travel/MOB",
        urlSlug: "travel",
        link: "/travel",
        urlTitle: "Travel",
        children: [],
        display: canUserAccess.value.nav_travel_mob,
    },
    {
        icon: ChartLine,
        title: "Performance",
        urlSlug: "performance",
        link: "/performance",
        children: [],
        display: canUserAccess.value.nav_performance,
    },
]);
</script>

<template>
    <div>
        <SideNav :nav-items="navItems" />

        <transition
            enter-active-class="animate__animated animate__faster animate__slideInLeft"
            leave-active-class="animate__animated animate__faster animate__slideOutLeft"
        >
            <MobileNavBar v-if="app.getMobileNavState" :nav-items="navItems" />
        </transition>
    </div>
</template>

<style lang="scss" scoped></style>
