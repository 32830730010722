<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import DialogModal from "../DialogModal.vue";
import { usePromtUser } from "@/composables/usePromptUser";

const promptUser = usePromtUser();
const cancelButtonRef = ref();

const {
    show,
    saveButtonText,
    cancelButtonText,
    promptMessage,
    promptTitle,
    hasThirdOption,
    thirdOptionButtonText,
} = storeToRefs(promptUser);
const { getUserInput, optionalFunc } = promptUser;
</script>

<template>
    <div>
        <DialogModal
            alignment-class="flex justify-center items-center z-[300]"
            :show-footer="false"
            :show="show"
        >
            <template #title>
                <h4
                    class="text-2xl text-center font-semibold leading-6 text-gray-900"
                >
                    {{ promptTitle }}
                </h4>
            </template>

            <template #content>
                <div>
                    <div class="mt-3 text-center sm:mt-5">
                        <div class="mt-2">
                            <p class="text-gray-500">
                                {{ promptMessage }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mt-5 flex space-x-4 items-center">
                    <button
                        ref="cancelButtonRef"
                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        type="button"
                        @click="getUserInput(false)"
                    >
                        {{ cancelButtonText }}
                    </button>

                    <button
                        class="inline-flex w-full justify-center rounded-md bg-success px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                        type="button"
                        @click="getUserInput(true)"
                    >
                        {{ saveButtonText }}
                    </button>

                    <button
                        v-if="hasThirdOption"
                        class="inline-flex w-full justify-center rounded-md bg-success px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                        type="button"
                        @click="optionalFunc"
                    >
                        {{ thirdOptionButtonText }}
                    </button>
                </div>
            </template>
        </DialogModal>
    </div>
</template>

<style lang="scss" scoped></style>
