<script setup>
import NavLink from "@/Components/Buttons/NavLink.vue";
import NavLinkDropdown from "@/Components/Buttons/NavLinkDropdown.vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import CloseIcon from "../Icons/CloseIcon.vue";
import { useAppStore } from "@/stores/AppStore";
import { onMounted, onUnmounted, watch } from "vue";

defineProps({
    navItems: {
        type: Array,
        default() {
            return [];
        },
    },
});

const app = useAppStore();

onMounted(() => {
    document.body.style.overflow = "hidden";
});
onUnmounted(() => {
    document.body.style.overflow = "initial";
});
watch(usePage(), () => {
    app.handleToggleMobileNav;
});
</script>

<template>
    <header
        class="bg-holbrook-dark-blue fixed left-0 right-0 z-[9999] top-0 bottom-0 overflow-y-scroll lg:hidden block"
    >
        <section
            class="bg-white w-full h-fit p-3 flex overflow-hidden gap-5 px-6 sticky top-0 z-100"
        >
            <span @click="app.handleToggleMobileNav">
                <CloseIcon />
            </span>
            <Link href="/">
                <ApplicationLogo class="h-fit" />
            </Link>
        </section>
        <ul class="text-sm lg:font-medium pt-6 pb-4" role="list">
            <template v-for="item in navItems" :key="item.title">
                <li v-if="item.display">
                    <span
                        v-if="!item.children.length > 0"
                        @click="app.handleToggleMobileNav"
                    >
                        <NavLink
                            :href="item.link"
                            :link-name="item.link"
                            :title="item.title"
                        >
                            <Component :is="item.icon" class="h-6 w-6" />
                        </NavLink>
                    </span>
                    <NavLinkDropdown
                        v-else
                        :href="item.link"
                        :items="item.children"
                        :title="item.title"
                        :icon="item.icon"
                        @handle-click-link="() => app.handleToggleMobileNav"
                    >
                        <Component :is="item.icon" class="h-6 w-6" />
                    </NavLinkDropdown>
                </li>
            </template>
            <li class="mt-8 w-full justify-center flex">
                <button
                    class="px-6 py-2 font-semibold rounded bg-holbrook-yellow hover:bg-ha-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ha-500"
                    @click="router.post('/logout')"
                >
                    Log Out
                </button>
            </li>
        </ul>
    </header>
</template>

<style lang="scss" scoped>
section svg {
    @apply h-8;
}
</style>
