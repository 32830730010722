<script setup>
import { computed, ref, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Link, usePage } from "@inertiajs/vue3";
import { useAppStore } from "@/stores/AppStore";

const props = defineProps({
    title: String,
    slug: {
        type: [String, Boolean],
        default: false,
    },
    display: { type: Boolean, default: null },
    icon: { default: null },
    parentIsChild: { type: Boolean, default: false },
    items: Array,
});
const app = useAppStore();

let show = ref(false);
const page = usePage();
const activeClassColor = computed(() => {
    if (
        page.url.startsWith(props.slug) ||
        isUrlInItems(props.items, page.url)
    ) {
        return "!text-holbrook-yellow border-l-2 border-holbrook-yellow !rounded-none";
    } else {
        return "!text-white";
    }
});

function isActive(link, exact) {
    const currentPath = page?.url.split("?")[0];

    return exact ? currentPath === link : currentPath.includes(link);
}

function isUrlInItems(items, url) {
    return items.some((item) => {
        const itemUrl = item.link?.toLowerCase();
        if (url.includes(itemUrl)) {
            return true;
        }
        if (item.children) {
            return isUrlInItems(item.children, url);
        }
        return false;
    });
}

watch(
    page,
    (updatedPageUrl) => {
        const currentUrl = updatedPageUrl.url.split("?")[0];
        show.value =
            currentUrl.startsWith(props.slug) ||
            isUrlInItems(props.items, currentUrl);
    },
    { immediate: true },
);
</script>

<template>
    <div v-if="display == null || display" class="relative cursor-pointer">
        <!-- Dropdown toggle button -->
        <div
            class="flex items-center justify-center h-12 max-h-12 min-h-12 pl-3 py-2 pr-4 cursor-pointer text-white hover:rounded-l-none hover:border-l-2 hover:border-holbrook-yellow rounded hover:!text-holbrook-yellow"
            :class="activeClassColor"
            @click="show = !show"
        >
            <span
                class="flex justify-center p-1 mr-3"
                :class="{ 'w-14': icon }"
            >
                <slot />
            </span>
            <span :class="{ 'w-3/5': icon }">{{ title }}</span>
            <span class="inline-block items-center ml-auto w-1/5">
                <FontAwesomeIcon
                    v-show="show"
                    icon="fa-solid fa-chevron-down"
                    class="ml-6 text-lg my-auto"
                />
                <FontAwesomeIcon
                    v-show="!show"
                    icon="fa-solid fa-chevron-right"
                    class="ml-6 text-lg my-auto"
                />
            </span>
        </div>

        <!-- Dropdown menu -->
        <div
            v-show="show"
            class="py-2 mt-2 bg-holbrook-dark-blue rounded-md lg:left-0 w-full"
        >
            <template v-for="item in items" :key="item.label">
                <span
                    v-if="item.display == undefined || item.display"
                    @click="app.handleToggleMobileNav"
                >
                    <a
                        v-if="item.external"
                        :href="item.link"
                        target="_blank"
                        class="cursor-pointer block z-50 px-4 py-2 text-sm hover:rounded-l-none hover:border-l-2 hover:border-holbrook-yellow hover:!text-holbrook-yellow pl-8 text-white"
                    >
                        {{ item.label }}
                    </a>
                    <NavLinkDropdown
                        v-else-if="item.children?.length"
                        :href="item.link"
                        :items="item.children"
                        :slug="item.urlSlug"
                        :title="item.title"
                        :icon="item.icon"
                    >
                        <Component :is="item.icon" class="h-6 w-6" />
                    </NavLinkDropdown>
                    <Link
                        v-else
                        :href="item.link"
                        class="cursor-pointer block z-50 px-4 py-2 text-sm hover:rounded-l-none hover:border-l-2 hover:border-holbrook-yellow hover:!text-holbrook-yellow pl-8"
                        :class="[
                            isActive(item.link, item?.exact)
                                ? activeClassColor
                                : '!text-white',
                        ]"
                    >
                        <span :class="{ 'ml-5': item.isGrandChild }">
                            {{ item.label }}
                        </span>
                    </Link>
                </span>
            </template>
        </div>
    </div>
</template>
