import "../css/app.css";
import "nprogress/nprogress.css";
import "./bootstrap";

import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createPinia } from "pinia";
import { createApp, h } from "vue";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import AppLayout from "./Layouts/AppLayout.vue";
import VueTippy from "vue-tippy";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./fontawesome";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "NOS";

const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        );
        if (name.startsWith("Auth/")) {
            page.default.layout ??= undefined;
        } else {
            page.default.layout ??= AppLayout;
        }
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        if (import.meta.env.VITE_SENTRY_APP_ENV !== "local") {
            // Sentry.init({
            //     app,
            //     options: { telemetry: false },
            //     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            //     environment: import.meta.env.VITE_SENTRY_APP_ENV,
            //     release: import.meta.env.VITE_SENTRY_RELEASE_ENV,
            //     integrations: [
            //         Sentry.browserTracingIntegration(),
            //         Sentry.replayIntegration({
            //             maskAllText: false,
            //             maskAllInputs: false,
            //         }),
            //     ],
            //     // Performance Monitoring
            //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: [
            //         "localhost",
            //         /^https:\/\/yourserver\.io\/api/,
            //     ],
            //     // Session Replay
            //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            // });
            // if (
            //     props.initialPage.props.auth &&
            //     props.initialPage.props.auth.user
            // ) {
            //     Sentry.setUser({
            //         id: props.initialPage.props.auth.user.id,
            //         email: props.initialPage.props.auth.user.email,
            //     });
            // }
        }

        app.use(plugin)
            .use(ZiggyVue)
            .component("font-awesome-icon", FontAwesomeIcon)
            .use(VueTippy, {
                directive: "tippy",
                component: "tippy",
                defaultProps: {
                    allowHTML: true,
                },
            })
            .use(Vue3Toasity, {
                theme: "auto",
                type: "default",
                position: "top-center",
                pauseOnHover: false,
                pauseOnFocusLoss: false,
                hideProgressBar: true,
                dangerouslyHTMLString: true,
            })
            .use(pinia)
            .mount(el);

        return app;
    },
});
