<script setup>
import { computed, onMounted, ref } from "vue";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import { toast } from "vue3-toastify";

const currentAnnouncement = ref([]);
const open = ref(false);
const message = computed(() => currentAnnouncement.value?.content || "");
const title = computed(() => currentAnnouncement.value?.title || "");
const scrollContainer = ref(null);
const isScrollable = ref(false);

const acknowledgeSave = () => {
    axios
        .post(route("announcements.acknowledge", currentAnnouncement.value.id))
        .then(() => {
            open.value = false;
        })
        .catch((error) => {
            toast.error(
                error.response?.data?.message ||
                    "Something went wrong with acknowledgement.",
            );
        })
        .finally(() => {
            getCurrentAnnouncements();
        });
};

const getCurrentAnnouncements = async () => {
    axios
        .get("/announcements/current")
        .then((response) => {
            currentAnnouncement.value = response.data;

            if (currentAnnouncement.value.id) {
                open.value = true;
                checkScrollable();
            }
        })
        .catch((error) => {
            toast.error(
                error.response?.data?.message ||
                    "Error fetching current announcement.",
            );
        });
};

const checkScrollable = () => {
    const el = scrollContainer.value;
    if (!el) return;
    isScrollable.value = el.scrollHeight > el.clientHeight;
};

onMounted(() => {
    getCurrentAnnouncements();
    checkScrollable();
});
</script>

<template>
    <TransitionRoot :show="open" as="template">
        <Dialog class="relative z-100" @close="open = false">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-auto">
                <div class="absolute inset-0 overflow-auto">
                    <div
                        class="pointer-events-none fixed inset-x-0 top-0 left-0 right-0 flex w-full max-w-full"
                    >
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="-translate-y-full"
                            enter-to="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-y-0"
                            leave-to="-translate-y-full"
                        >
                            <DialogPanel class="pointer-events-auto w-full">
                                <div
                                    class="flex h-full flex-col bg-white py-6 shadow-xl"
                                >
                                    <div class="px-4 sm:px-6">
                                        <div
                                            class="flex items-start justify-between"
                                        >
                                            <DialogTitle
                                                class="text-base font-semibold text-gray-900"
                                            >
                                                {{ title }}
                                            </DialogTitle>
                                            <div
                                                class="ml-3 flex h-7 items-center"
                                            >
                                                <button
                                                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    type="button"
                                                    @click="open = false"
                                                >
                                                    <span
                                                        class="absolute -inset-2.5"
                                                    />
                                                    <span class="sr-only"
                                                        >Close panel</span
                                                    >
                                                    <XMarkIcon
                                                        aria-hidden="true"
                                                        class="size-6"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="relative mt-6 flex-1 px-4 sm:px-6"
                                    >
                                        <div
                                            ref="scrollContainer"
                                            class="relative max-h-64 overflow-auto"
                                        >
                                            <div
                                                :class="{
                                                    'has-more-content':
                                                        isScrollable,
                                                }"
                                                class="overflow-auto h-full"
                                            >
                                                <span v-html="message"></span>
                                            </div>
                                            <span
                                                :class="[
                                                    isScrollable
                                                        ? 'block'
                                                        : 'hidden',
                                                ]"
                                                class="sticky bottom-0 shadow-lg text-center bg-gray-50 text-xs text-gray-700"
                                            >
                                                Scroll to view more.
                                            </span>
                                        </div>

                                        <template
                                            v-if="currentAnnouncement.media"
                                        >
                                            <div
                                                v-for="media in currentAnnouncement.media"
                                                :key="media.id"
                                                class="mt-4 overflow-auto max-h-64"
                                            >
                                                <a
                                                    v-if="
                                                        media.extension ===
                                                        'pdf'
                                                    "
                                                    :href="media.embed_link"
                                                    class="py-4 text-blue-500 underline hover:text-blue-600"
                                                    target="_blank"
                                                >
                                                    {{
                                                        `Download ${media.display_name}`
                                                    }}
                                                </a>
                                                <img
                                                    v-else
                                                    :alt="
                                                        media.name ||
                                                        'Image preview'
                                                    "
                                                    :src="media.temporary_link"
                                                    class="max-h-64 object-cover"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div class="mt-8">
                                                <div class="flex">
                                                    <button
                                                        class="rounded-md bg-yellow-200 border border-yellow-800 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                                        type="button"
                                                        @click="acknowledgeSave"
                                                    >
                                                        Acknowledge
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<style lang="scss" scoped>
.has-more-content {
    @apply border-b-2 border-gray-200;
}
</style>
